export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "Capstone Property",
    contact_email: "cc@capstoneproperty.com",
    company_phone: "+974 7770 4768",
    available_time: "Monday - Thursday: 08:30 am – 5:30 pm",
    available_days: "Saturday - Sunday: 08:30 am – 5:30 pm",
    address: "Capstone Property, Unit 106, Qanat Quartier, The Pearl - Doha Qatar",
    primary_color: "#45828C"
}


export const Site_Vars = {
    default_currency: "QAR "
}
export const OFF_PLAN = "new_development"
export const COMMERCIAL = "commercial"
